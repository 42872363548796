<template>
  <div class="wow slideInLeft" style="width:100%">
    
    <div style="height:650px;">

      <div>
          <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/about_banner.png" style="height:100%;width:100%"/>
      </div>

      <div style="position:absolute;top:200px;left:41%;text-align:cneter">
            <p style="color:white;font-weight:500;font-size:44px;line-height:60px;text-align:center">华财企服</p>
            <p style="line-height:30px;color:white;font-weight:400;font-size:18px;margin-top:20px">人力资源及企业成本优化服务商</p>
            <el-button type="primary" style="margin-top:30px;width:100%">立即体验</el-button>
        </div>
    </div>

    <div class="block main marginTop wow slideInLeft">
      <el-row>
        <el-col :offset="1" :span="10" style="margin-top:1%;">
          <p style="color:#000;font-size:40px;font-family: Microsoft YaHei;margin-bottom:20px">公司介绍</p>
          <p style="color:#666;text-indent:36px;font-size:18px;margin-bottom:20px;line-height:34px">华财企服（北京）科技有限公司（简称“华财企服”）2020年4月创立于北京，是湖南省商务人力资源交流服务中心有限公司联合地方政府重点扶持共同打造的现代共享经济服务平台公司。 </p>
          <p style="color:#666;text-indent:36px;font-size:18px;margin-bottom:20px;line-height:34px">基于互联网云计算、大数据，依托自主研发的薪酬管理SaaS平台、灵活用工SaaS平台，华财企服以优化企业经营模式，减轻企业综合成本为核心，为广大企业和个人提供众包分包，经营所得发放，用工关系管理，灵活就业支持等服务，让企业人资部门、财务部门与业务部门一起成为创造经济价值的利润中心。</p>
          <p style="color:#666;text-indent:36px;font-size:18px;margin-bottom:20px;line-height:34px"> 华财企服业务覆盖互联网、共享经济、物流配送、餐饮、教育、建筑、新零售、金融等多个行业。截止目前，已累计为10000+客户提供专业的人力资源等相关服务，累计代发放10亿元，节省成本0.6亿元。</p>

        </el-col>
        <el-col :span="2" style="">
           &nbsp;
        </el-col>
        <el-col :span="11">
          <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/about_company.jpg">
        </el-col>
      </el-row>
      <el-row>
          <el-col>
          </el-col>
      </el-row>
    </div>
    <div style="background-color:white;height:500px;padding-top:10px;margin-top:20px;border-radius: 6px;">
      <p style="text-align: center;color: #2c3e50;font-weight：700;font-size:40px;margin-top:40px">企业文化</p>

      <el-row style="margin-top:50px;z-index:15">
        <el-col :span="6" :offset="2">
          <el-card  shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/cultule1.png" style="width:100%"/>
            <div style="padding: 14px;position:absolute;top:60%;color:#fff;font-size:30px">
              <span>使命</span><br>
              <span style="font-size:14px">为企业发展而努力创新</span>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6" :offset="1">
          <el-card shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/cultule2.png" style="width:100%"/>
            <div style="padding: 14px;position:absolute;top:60%;color:#fff;font-size:30px">
              <span>愿景</span><br>
              <span style="font-size:14px">服务全行业</span>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6" :offset="1">
          <el-card  shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/cultule3.png" style="width:100%"/>
            <div style="padding: 14px;position:absolute;top:60%;color:#fff;font-size:30px;width">
              <span>价值观</span><br>
              <span style="font-size:14px">开放 创新 合规 守信</span>
            </div>
          </el-card>
        </el-col>

      </el-row>
      
    </div>
    <div style="background-color:white;height:1200px;padding-top:10px;margin-top:20px;border-radius: 6px;">
      <p style="text-align: center;color: #2c3e50;font-weight：700;font-size:40px;margin-top:40px">发展历程</p>
      <div class="block" style="margin-left:10%">
        <el-timeline>
          <el-timeline-item timestamp="2020-07-15" placement="top" type="success"  size="large">
            <el-card>
              <h4>公司成立</h4>
              <p style="line-height:40px">于2020年07月15日公司成立，注册资金100万元</p>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2020-08-10" placement="top" type="success"  size="large">
            <el-card>
              <h4>开展业务</h4>
              <p style="line-height:40px">与2020年08月10开始，正式开展各项业务</p>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2020-12-01" placement="top" type="success"  size="large">
            <el-card>
              <h4>系统研发</h4>
              <p style="line-height:40px">研发基础云计算、大数据的SAAS灵活用工平台</p>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2021-06-01" placement="top" type="success"  size="large">
            <el-card>
              <h4>系统上线</h4>
              <p style="line-height:40px">2021年06月01，发布华财企服灵活用工PC版和小程序</p>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2021-12-05" placement="top" type="success"  size="large">
            <el-card>
              <h4>系统上线</h4>
              <p style="line-height:40px">签约湖南郴州税源地（嘉旺）</p>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2022-03-01" placement="top" type="success"  size="large">
            <el-card>
              <h4>系统上线</h4>
              <p style="line-height:40px">于2022年03月，签约湖南税源地（财达）</p>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2022-05-01" placement="top" type="warning"  size="large">
            <el-card>
              <h4>业务扩展</h4>
              <p style="line-height:40px">业务正在急速扩展，团队规模不断扩大</p>
            </el-card>
          </el-timeline-item>

        </el-timeline>
      </div>
      
    </div>
    
    
  </div>
</template>

<script>
import axios from 'axios';
import WOW from 'wowjs';
import { tryHideFullScreenLoading, showFullScreenLoading } from '../network/serviceHelp'
  export default {
  inject: ['reload'],
  data() {
    return {
     
    }
  },
  methods:{
      

  },
  created(){
   
  },
  mounted(){
    let wow = new WOW.WOW({
          boxClass: 'wow',
          animateClass: 'animated',
          offset: 0,
          mobile: true,
          live: false
        });
        wow.init();
  }
  }
</script>

<style lang="scss" scoped>
.el-pagination {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
</style>